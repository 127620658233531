<template>
    <div class="card-tail card-f-h" v-loading="$waiting.is('loading')">
        <Table :table-data="filteredData" />
    </div>
</template>
<script>
import Api from "./k10_declaration.api";

export default {
    components: {
        Table: () => import(/* webpackChunkName: "K10DeclarationTable" */ "./components/K10DeclarationTable.vue"),
    },

    data() {
        return {
            itemsData: [],
            filteredData: [],
        };
    },

    watch: {
        isGlobalClientSelected: function() {
            this.getFilteredData();
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "K10 Declaration");
        document.title = "K10 Declaration";
    },

    created() {
        this.getAll();
    },

    methods: {
        getFilteredData() {
            if (this.isGlobalClientSelected) {
                this.filteredData = this.itemsData.filter(item => item.clientId == this.$store.state.topBar.selectedClient.value);
            } else {
                this.filteredData = this.itemsData;
            }
        },

        async getAll() {
            this.$waiting.start("loading");
            this.itemsData = await Api.getAll();
            this.getFilteredData();
            this.$waiting.end("loading");
        },
    },

    computed: {
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
